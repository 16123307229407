import React from 'react';
import PropTypes from 'prop-types';
import Seo from '../components/seo';
import Loading from '../components/loading';
import 'ress';
import '../styles/common/common.scss';
import '../styles/common/common-bankto.scss';
import '../styles/common/init-animation.scss';

const Root = ({ children }) => (
  <>
    <Seo />
    <Loading />
    {children}
  </>
);

Root.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Root;
