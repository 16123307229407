import React from 'react';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Ogp from '../images/ogp.jpg';

const SEO = ({ meta }) => (
  <HelmetProvider>
    <Helmet
      htmlAttributes={{ lang: 'ja' }}
      title="合同会社バンクトゥ｜bankto LLC."
      meta={[
        { name: 'description', content: 'バンクトゥは「編集」という職能をベースに、戦略設計・コンテンツ・デザイン・エンジニアリングをカバーする、京都のクリエイティブ・ファームです。メディア制作を中心に、芸術文化、観光や地域に関わる課題解決を行なっています。' },
        { property: 'og:title', content: '合同会社バンクトゥ｜bankto LLC.' },
        { property: 'og:description', content: 'バンクトゥは「編集」という職能をベースに、戦略設計・コンテンツ・デザイン・エンジニアリングをカバーする、京都のクリエイティブ・ファームです。メディア制作を中心に、芸術文化、観光や地域に関わる課題解決を行なっています。' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://bankto.co.jp' },
        { property: 'og:image', content: Ogp },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:creator', content: '' },
        { name: 'twitter:title', content: '合同会社バンクトゥ｜bankto LLC.' },
        { name: 'twitter:image', content: Ogp },
        { name: 'twitter:description', content: 'バンクトゥは「編集」という職能をベースに、戦略設計・コンテンツ・デザイン・エンジニアリングをカバーする、京都のクリエイティブ・ファームです。メディア制作を中心に、芸術文化、観光や地域に関わる課題解決を行なっています。' },
        { name: 'google-site-verification', content: 'oJW1zmt1FmKO9gACA1eOPwfjZXwhPI_fQ2vUTMM6TdM' },
      ].concat(meta)}
    >
      <script>
        {`
          {
            (function(d) {
              var config = {  
                kitId: 'con4gft',
                scriptTimeout: 3000,
                async: true
              },
              h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\\bwf-loading\\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
            })(document);
          }
        `}
      </script>
    </Helmet>
  </HelmetProvider>
);

SEO.defaultProps = {
  meta: [],
};

SEO.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
