import { createStore as reduxCreateStore } from 'redux';
import { ISLOADED, CURSOR, TRANSITION } from '../actions';

const reducer = (state, action) => {
  switch (action.type) {
    case ISLOADED:
      return { ...state, isLoaded: true };
    case CURSOR:
      return {
        ...state,
        cursorX: action.cursorX,
        cursorY: action.cursorY,
      };
    case TRANSITION:
      return {
        ...state,
        isTransition: action.isTransition,
      };
    default:
      return state;
  }
};

const initialState = {
  isLoaded: false,
  cursorX: 0,
  cursorY: 0,
  isTransition: false,
};

const createStore = () => reduxCreateStore(reducer, initialState);

export default createStore;
