import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import gsap, { Expo } from 'gsap';
import { isIE } from 'react-device-detect';
import Style from '../styles/common/loading.module.scss';

const mapStateToProps = ({ isLoaded }) => ({ isLoaded });

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.isLoaded = props.isLoaded;
  }

  componentDidMount() {
    if (!this.isLoaded) {
      const loading = document.querySelector('.loading');
      const logo = document.querySelector('.logo');
      const svg = Array.from(document.querySelectorAll('.svg'));
      const loadingBg = document.querySelector('.loadingBg');
      const layout = document.querySelector('.layout');
      const tl = gsap.timeline();
      layout.style.opacity = 0;
      window.requestAnimationFrame(() => {
        tl
          .to(loading, {
            delay: 1,
            duration: 0.5,
            translateX: '100%',
            ease: Expo.easeOut,
            onStart: () => {
              svg.forEach((e) => { e.style.fill = '#FFFF28'; });
              layout.style.opacity = 1;
            },
          })
          .to(logo, {
            autoAlpha: 0,
            ease: Expo.easeOut,
          }, 1.4)
          .to(loadingBg, {
            duration: 0.2,
            translateX: '100%',
            ease: Expo.easeOut,
            onComplete: () => {
              document.body.style.position = 'static';
              loading.parentNode.removeChild(loading);
              logo.parentNode.removeChild(logo);
              loadingBg.parentNode.removeChild(loadingBg);
              const { dispatch } = this.props;
              dispatch({ type: 'ISLOADED' });
            },
          }, 1.5);
      });
    }
  }

  render() {
    if (isIE) return <div className={`${Style.loading} ${Style.ie_is_not_supported}`}><p>IEはサポートされていません。<br />Chrome / Opera / Firefoxをダウンロードしてください。</p></div>;
    return (
      <>
        {!this.isLoaded && (
          <>
            <div className={`loading ${Style.loading}`} />
            <div className={`loadingBg ${Style.loadingBg}`} />
            <p className={`logo ${Style.logo}`}>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 116 24.2"
              >
                <path className="svg" d="M9.4,8.1c-2,0-3.4,0.5-4.7,1.8H4.7V0.1L4.7,0H0.1L0,0.1v23.7l0.1,0.1h2.7l0.1-0.1l1-1.3h0.1c1.3,1.2,2.9,1.7,4.7,1.7c4.5,0,8.1-3.5,8.1-8.2C16.7,11.8,13.8,8.1,9.4,8.1 M8.1,20.1c-2.4,0-3.7-1.6-3.7-3.9c0-2.3,1.3-3.9,3.7-3.9c2.4,0,3.9,1.6,3.9,3.9C12.1,18.5,10.5,20.1,8.1,20.1" />
                <path className="svg" d="M27.8,8.1c-2.3,0-5.1,0.7-6.7,2.2v0.1L23,13h0.1c1.1-0.9,3-1.4,4.3-1.4c1.6,0,2.8,0.8,2.8,2.5c-3.9,0-10.4,0.1-10.4,5.4c0,3.1,2.6,4.7,5.8,4.7c2,0,3.6-0.8,4.5-1.7h0.1l0.9,1.3l0.1,0.1h3.2l0.1-0.1v-7.7C34.5,10.9,33.3,8.1,27.8,8.1 M30.1,18.1c-0.2,1.7-1.9,3-3.6,3c-1,0-2.2-0.5-2.2-1.7c0-2.1,3.2-2.2,4.7-2.2h1.1l0.1,0.1C30.2,17.2,30.2,17.5,30.1,18.1" />
                <path className="svg" d="M39.7,8.6L39.7,8.6l4.5-0.1l0.1,0.1v1.9h0.1c0.6-1.2,2.2-2.3,4.5-2.3c4.8,0,5.7,3.2,5.7,7.3v8.4l-0.1,0.1h-4.6l-0.1-0.1v-7.4c0-1.8,0-4-2.5-4c-2.5,0-2.8,2.1-2.8,4v7.4l-0.1,0.1h-4.6l-0.1-0.1V8.6z" />
                <polygon className="svg" points="59.6,0.1 59.7,0 64.3,0 64.4,0.1 64.4,14.1 64.4,14.1 68.3,8.6 68.4,8.5 73.5,8.5 73.5,8.6 68.8,15.4 68.8,15.5 74.5,23.8 74.5,23.9 69.1,23.9 69,23.8 64.4,16.9 64.4,16.9 64.4,23.8 64.3,23.9 59.7,23.9 59.6,23.8" />
                <path className="svg" d="M116,11.6l-2.2-1.8l-0.2,0c-0.4,0.2-0.7,0.5-1.2,0.6c-1.3-1.5-3.3-2.3-5.7-2.3c-4.7,0-9.1,3.2-10.2,8c-1.1,4.9,2.1,8,6.8,8c4.7,0,9.1-3.2,10.2-8c0.2-1,0.3-2,0.2-2.8c0.6-0.3,1.2-0.7,1.7-1.1c0.2-0.2,0.4-0.4,0.6-0.6L116,11.6zM108.8,16.2c-0.5,2.3-2.5,4-4.8,4c-2.4,0-3.3-1.7-2.8-4c0.5-2.3,2.5-4,4.8-4C108.4,12.1,109.4,13.9,108.8,16.2" />
                <path className="svg" d="M91,19.2c-0.5,0.4-1.1,1-1.8,1.3c-0.5,0.2-0.9,0.2-1.1,0.1c-0.3-0.2-0.4-0.5-0.4-0.8c0-0.3,0.5-1.9,0.5-1.9l1.2-4h4l0.1-0.1l1.2-3.4l-0.1-0.1h-4.1l1.2-4l-0.1-0.1h-4.2l-0.1,0.1l-1.3,4h-2.4l-0.1,0.1l-1.1,3.4l0.1,0.1h2.3l-1,2.9c-0.5,1.7-0.7,2.3-0.8,3.1c-0.2,1.5,0.4,2.6,1.3,3.3c0.7,0.5,1.7,1,3.1,1c2.3,0,3.9-1.5,4.9-2.5c0.2-0.2,0.4-0.4,0.5-0.5l0-0.1l-1.9-1.9H91z" />
              </svg>
            </p>
          </>
        )}
      </>
    );
  }
}

Loading.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Loading);
